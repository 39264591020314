:root {
  --grey: rgb(173, 173, 173);
  --dimmedGrey: rgb(148, 148, 148);
  --linkBlue: #6376a3;
  --highlightBlue: rgb(0, 183, 255);
  --highlightBluePop: rgb(253, 38, 156);
  --hotRed: rgb(146, 0, 0);
  --knowHowCopy: #292929;
}

#App {
  /* background-color: rgba(222, 184, 135, 0.383); */
  position: absolute;
  width: 100%;
  /* display: flex; */
  /* flex-direction: column; */
}
.sff {
  font-weight: bold;
  text-transform: uppercase;
  color: white;
}
/* .backgroundImage {
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;
} */
.backgroundGradient {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;

  width: 100%;
  height: 100%;
}

.navbar {
  padding-top: 1rem;
}

.siteWrapper {
  position: relative;
  width: 90%;
  height: fit-content;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* background-color: blueviolet; */
  overflow-x: hidden;
}
.siteWrapper.knowhow {
  margin-top: 1rem;
  background-color: rgba(255, 255, 255, 0);
  color: #292929;
  padding-bottom: 10rem;
  text-align: left;
}
.knowhow .copy {
  font-family: "Roboto Slab", serif;
}

.knowhow h1 {
  color: var(--knowHowCopy);
  padding-bottom: 1rem;
}

.contentWrapper {
  margin-top: 5rem;
  max-width: 100%;
}

@media (min-width: 890px) {
  .contentWrapper {
    width: 800px;
    /* background-color: red; */
  }
}
h1 {
  color: white;
  text-transform: uppercase;
  font-size: 3rem;
  margin-bottom: 0rem;
  margin-top: 0rem;
  line-height: 120%;
}

.h2Box {
  /* font-family: "Shippori Mincho B1", serif; */
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

h2 {
  color: white;
  text-transform: uppercase;
  font-size: 1.8rem;
  margin-top: 10rem;
  margin-bottom: 0rem;
  padding-top: 0.7rem;
  border-top: rgb(194, 194, 194) solid 1px;

  line-height: 3rem;
}

@media (max-width: 410px) {
  h2 {
    font-size: 1.2rem;
  }
}

h3 {
  color: white;
  line-height: 2rem;
  margin-top: 1rem;
  margin-bottom: 0px;
  text-align: center;
}
.subline {
  margin-top: 2rem;
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: bold;
  /* background-color: aquamarine; */
  width: calc(100% - 0%);
  padding-left: 0%;
  padding-right: 0%;
}
.sublineSegment {
  display: inline-block;
}

.claim {
  width: calc(100%-10%);
  margin-top: 3rem;

  /* line-height: 120%; */
  padding-left: 5%;
  padding-right: 5%;
}

.subClaimWrapper {
  grid-template-columns: 6.25% 25% 6.25% 25% 6.25% 25% 6.25%;
  grid-template-areas: "s1 aa s2 bb s3 cc s4";
  margin-top: 50px;
  width: 100%;
  display: grid;
  /* align-content: space-between;
  gap: 5%; */
  font-size: 1rem;
}

@media (max-width: 500px) {
  .subClaimWrapper {
    font-size: 0.8rem;
    line-height: 130%;
  }
}

.subClaim {
  padding-top: 0.6rem;
  text-align: center;
  border-top: solid rgb(194, 194, 194) 1px;
  color: white;
}

.subClaim.aa {
  grid-area: aa;
}
.subClaim.bb {
  grid-area: bb;
}
.subClaim.cc {
  grid-area: cc;
}

.copy {
  margin-top: 2rem;
  width: calc(100% - 2rem);
  padding: 0rem 1rem 0rem 1rem;
  text-align: left;
  /* line-height: 1.4rem; */
}
.whyWithUs.copy {
  width: calc(100% - 20%);
  padding: 0rem 10% 0rem 10%;
  text-align: center;
}

.focus {
  font-weight: bold;
  color: white !important;
}

.statement {
  margin-top: 1rem;
  color: var(--grey);
}

.whyWithUs .statement {
  padding: 0% 10% 0% 10%;
  text-align: center;
  width: calc(100% - 20%);
}

@media (max-width: 500px) {
  .whyWithUs .statement {
    padding: 0% 0% 0% 0%;
    /* background-color: rgb(128, 179, 255); */
    text-align: center;
    width: 100%;
  }
}

.clip-circle {
  clip-path: circle(100px at center);
  width: 100px;
  height: 100px;
}

.personPoster {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 3rem;
}

.picture {
  clip-path: circle(100px at center);
  width: 200px;
  height: 200px;
  background-color: rgb(255, 255, 255);
  background-repeat: no-repeat;
  background-size: cover;
}

.picture.jochen {
  background-image: url("./assets/jochen.jpg");
}
.picture.tim {
  background-image: url("./assets/tim.jpg");
}
.picture.marc {
  background-image: url("./assets/marc.jpg");
}

.bulletWord {
  width: 100%;
  height: max-content;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 2rem;
  margin-top: 3rem;
}

@media (max-width: 410px) {
  .bulletWord {
    font-size: 1.2rem;
  }
}
.bulletWord .text {
  /* border-bottom: white solid 2px; */
  width: fit-content;
  text-align: center;
  padding-top: 0.5rem;
}

.mainLogoLarge {
  margin-top: 30px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainLogoSmall {
  position: relative;
  display: inline;

  background-color: rgba(0, 255, 255, 0.194);
  transform: scale(0.3);
  height: 10rem;
  width: 10rem;
}

.logoWrapper {
  width: 200px;
  height: 200px;
  /* background-color: rgba(102, 51, 153, 0.281); */
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotate 50s linear infinite;
}
.mainLogoSmall .logoWrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}

.logoWrapper .segment {
  position: absolute;
  width: 100px;
  height: 200px;
  border-radius: 50px;
  /* background-color: rgba(250, 235, 215, 0.507); */
  /* border: solid white 2px; */
  animation: pulse 3s alternate infinite ease-in-out;
  box-sizing: border-box;

  box-shadow: inset 0px 0px 20px 8px #fff;
}
.mainLogoSmall .logoWrapper .segment {
  height: 100%;
  width: 50%;
}

.logoWrapper .segment.b {
  transform: rotate(60deg);
  animation-delay: 1.5s;
}
.logoWrapper .segment.c {
  transform: rotate(-60deg);
  animation-delay: 3s;
}

@keyframes pulse {
  from {
    box-shadow: inset 0px 0px 20px 8px #fff;
  }
  to {
    box-shadow: inset 0px 0px 20px 8px rgb(128, 179, 255);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.white {
  color: white;
}

.bold {
  font-weight: bold;
}

.center {
  padding: 0% 10% 0% 10%;
  text-align: center;
  width: calc(100% - 20%);
}

.cardanoLink {
  text-decoration: none;
  color: var(--highlightBluePop);
  font-weight: bold;
}
.cardanoLink.white {
  color: #fff;
}
.cardanoLink.pool {
  padding: 2rem;
  line-height: 3rem;
}

.cardanoLink.info {
  padding: 2rem;
  line-height: 3rem;
  color: rgb(255, 255, 255);
}

.cardanoLink:visited {
  text-decoration: none;
}

.infoSmall {
  font-size: 0.7rem;
  line-height: 100%;
}

.nobreak {
  display: inline-block;
}

.btnInfo {
  /* background-color: var(--linkBlue); */
  background-color: rgba(255, 255, 255, 0);
  color: var(--linkBlue);
  border-radius: 5px;
  font-weight: bold;
  margin: 1.5rem;

  min-width: 8rem;
  padding-left: 1rem;
  padding-right: 1rem;

  height: 2rem;
  font-size: 1rem;
  border: solid var(--linkBlue) 1px;
  text-transform: uppercase;

  user-select: none;
}
/* .btnInfo * {
  text-transform: uppercase;
} */

.monitoring {
  visibility: visible;
  /* background-color: aquamarine; */
  overflow: hidden;
  transition: 0.1s 0.2s ease-in-out;
  height: fit-content;
  opacity: 1;
}
.monitoring.hide {
  visibility: collapse;
  height: 0px;
  opacity: 0;
}

.centerBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.serverDetails {
  display: flex;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;

  justify-content: space-between;
  flex-direction: row;
  font-size: 0.8rem;
  text-align: left;
  margin-bottom: 1rem;
}

@media (max-width: 500px) {
  .serverDetails {
    flex-direction: column;
  }
}

.social {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* margin-top: 15rem; */
  flex-direction: column;
  /* background-color: aqua; */
  margin-top: 10rem;
}
.socialLink {
  margin: 0rem;
  padding: 0rem;
}
.social img {
  height: 1.5rem;
  width: 1.5rem;
}
.cardanoLink.socialLink {
  font-size: 1rem;
  color: #fff;
  height: 2rem;
  display: flex;
  align-items: center;
}
.cardanoLink.binance {
  color: #f5cb23;
}
.disclaimer {
  width: 100%;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  color: #292929;
  border-top: solid 1px #292929;
}

.left {
  text-align: left;
}

.poolDetails {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.poolDetails .info {
  width: 100%;
  font-size: 1rem;
  line-height: 1.3rem;
}
.epsilon {
  white-space: nowrap;
  text-overflow: ellipsis;

  display: block;
  overflow: hidden;
}

.poolBox {
  position: relative;
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;

  margin-top: 7rem;
  border: 1px solid rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.536);
}
.poolBox:hover {
  border-color: var(--highlightBlue);
}

.poolBox h2 {
  margin-top: 3px;
  border: none;
}
/* .poolBox:hover h2 {
  color: var(--highlightBlue);
} */

.cardanoLink:hover {
  color: var(--highlightBlue);
}

.btnInfo:hover {
  border-color: var(--highlightBlue);
  color: var(--highlightBlue);
}

.poolID {
  position: relative;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 0.8rem;
}

.poolID .btnInfo {
  margin-top: 0.4rem;
}

/* .poolIframe {
  transform: scale(0.2);
} */

.navbar {
  z-index: 10;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2rem;

  padding-right: 10rem;
  text-align: center;

  background-color: rgba(0, 0, 0, 0.432);
  backdrop-filter: blur(10px);
}

.navbar a {
  text-decoration: none;
  color: #fff;
  padding-right: 2rem;

  font-weight: bold;

  text-align: left;
  /* background-color: rgb(146, 0, 0); */
}

.pdfHowTo {
  /* background-color: #6376a3; */
  width: 100%;
}

.poolData {
  display: flex;
  flex-direction: column;
  /* line-height: 3rem;
  font-size: 1.2rem;
  font-weight: bold; */
}

.performance {
  text-shadow: 0px 0px 15px #000000;
  color: rgb(211, 211, 211);
  /* text-align: left; */
  /* margin-left: 30%; */
}

.perf_value {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  /* color: var(--highlightBlue); */
}

.perf_name {
  color: var(--gery);
  line-height: 1.2rem;
  font-size: 0.7rem;
  font-weight: normal;
  margin-bottom: 1.8rem;

  /* color: var(--dimmedGrey); */
}

@media (max-width: 500px) {
  .perf_name {
    font-size: 0.7rem;

    /* margin-left: 20%; */
  }
}

.coopsBox__Wrapper {
  width: 100%;
}

.padding1Rem {
  padding-bottom: 1rem;
}
