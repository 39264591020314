body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*  */
  text-align: center;
  font-family: "Helvetica", "Arial", sans-serif;
  /* background-color: rgb(0, 33, 65); */
  background-color: rgb(0, 0, 0);
  /* color: white; */
  font-size: 1.2rem;
  line-height: 140%;
  background-image: url("./assets/mountains.jpg");
  /* filter: blur(0px); */
  /* opacity: 1; */

  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  color: white;
  max-width: 100%;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
